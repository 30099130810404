

const wsasHeadingTxts = [
    "",
    "Not at all",
    "",
    "Slightly",
    "",
    "Definitely",
    "",
    "Markedly",
    "",
    "Very Severely"
  ];

const wsasHeadingNums = [
    "",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
    ];

const wsasQuestionTxts = [
    "Because of my [problem] my ability to work is impaired. '0' means 'not at all impaired' and '8' means very severely impaired to the point I can't work.",
    "Because of my [problem] my home management (cleaning, tidying, shopping, cooking, looking after home or children, paying bills) is impaired.",
    "Because of my [problem] my social leisure activities (with other people e.g. parties, bars, clubs, outings, visits, dating, home entertaining) are impaired.",
    "Because of my [problem], my private leisure activities (done alone, such as reading, gardening, collecting, sewing, walking alone) are impaired.",
    "Because of my [problem], my ability to form and maintain close relationships with others, including those I live with, is impaired."
].map((q,i) => `${i + 1}. ${q}`);

const normaliseWSASTab = tab => {
    const questions = wsasQuestionTxts.map((q, i) => 
        ({
            name: `WSASAnswer${i + 1}`,
            text: q,
            required: "True",
            controlType: "RadioButton",
            InjurySpecific: "False",
            value: "0",
            Item: wsasHeadingNums.map((v, j) => ({
                    Text: v,
                    Value: j
            })),
            QuestionIndex: 39 + i,
            Object: "WSAS"
        }));

    const outerQuestion = {
        name: "WSAS",
        text: "WSAS",
        PerInjury: "False",
        InjurySpecific: "False",
        Object: "WSAS",
        controlType: "tabledquestionnaire",
        mandatory: "true",
        required: "True",
        validationWarning: "This information is required to submit the form",
        TableHeadingText: wsasHeadingTxts,
        TableHeadingNumber: wsasHeadingNums,
        Question: questions
    };

    const newTab = {
        name: "WSAS",
        text: "WSAS",
        Question: [outerQuestion]
    };

    return newTab;
}

export default normaliseWSASTab;