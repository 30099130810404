//input components
import { TextInputWithLabel } from "../../../../../SharedComponents/InputComponents/TextInput";

//label components
import { FormLabelWithLabel } from "../../../../../SharedComponents/FormComponents/FormLabel";

const Address = ({ addressDetails, handleChange, readOnly = false }) => {
  const { Address1, Address2, Address3, Town, County, Country, Postcode } =
    addressDetails;
  return (
    <>
      {!readOnly && (
        <div>
          <TextInputWithLabel
            inputLabel='Address Line 1'
            name='Address1'
            value={Address1 ?? ""}
            handleChange={handleChange}
          />
          <TextInputWithLabel
            inputLabel='Address Line 2'
            name='Address2'
            value={Address2 ?? ""}
            handleChange={handleChange}
          />
          <TextInputWithLabel
            inputLabel='Address Line 3'
            name='Address3'
            value={Address3 ?? ""}
            handleChange={handleChange}
          />
          <TextInputWithLabel
            inputLabel='Town'
            name='Town'
            value={Town ?? ""}
            handleChange={handleChange}
          />
          <TextInputWithLabel
            inputLabel='County'
            name='County'
            value={County ?? ""}
            handleChange={handleChange}
          />
          <TextInputWithLabel
            inputLabel='Postcode'
            name='Postcode'
            value={Postcode ?? ""}
            handleChange={handleChange}
          />
        </div>
      )}
      {readOnly && (
        <div>
          <FormLabelWithLabel label='Address Line 1' displayValue={Address1} />
          <FormLabelWithLabel label='Address Line 2' displayValue={Address2} />
          <FormLabelWithLabel label='Address Line 3' displayValue={Address3} />
          <FormLabelWithLabel label='Town' displayValue={Town} />
          <FormLabelWithLabel label='County' displayValue={County} />
          <FormLabelWithLabel label='Country' displayValue={Country} />
          <FormLabelWithLabel label='Postcode' displayValue={Postcode} />
        </div>
      )}
    </>
  );
};
export default Address;
