import { useState, useEffect } from "react";

//api calls
import { saveCorrespondence, sendCorrespondence } from "../Transactions";
import { getSubjectTypes } from "./EmailTemplateTransactions";

//input components
import { SelectInput } from "../../../../../SharedComponents/InputComponents/SelectInput";
import { TextInputBorderBox } from "../../../../../SharedComponents/InputComponents/TextInput";
import { DateInput } from "../../../../../SharedComponents/InputComponents/DateInput";

//label components
import { FormLabel } from "../../../../../SharedComponents/FormComponents/FormLabel";

//page components
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileUpload from "../../../../../SharedComponents/FileComponents/FileUpload";

//functions
import { handleInputChange } from "../../../../../SharedComponents/Utils/InputUtils";

//styling
import "./EmailTemplateStyles.css";
import "../../../../../SharedComponents/Styles/SharedGenericStyles.css";
import "../../../AppUtils/Styles/ContainerStyles.css";

//button components
import { Button } from "../../../../../SharedComponents/ButtonComponents/Button";

//page management
import { CallStates } from "../../../../../SharedComponents/AppStates/Utils";
import PageState from "../../../../../SharedComponents/AppStates/PageState";

const EmailTemplate = ({
  api,
  recipients = [],
  existingEmail = null,
  facilityID,
  setParentErrorMessage,
  setParentCallState,
  closeModal,
  isSave = false,
}) => {
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [availableRecipients, setAvailableRecipients] = useState([]);
  const [subjectTypes, setSubjectTypes] = useState([]);

  const [selectedRecipients, setSelectedRecipients] = useState([]); // for eventual use
  const [files, setFiles] = useState([]);

  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (callState === CallStates.INITIAL) {
      getSubjectTypes(api, setCallState)
        .then((data) => {
          setSubjectTypes(data.SubjectTypes);

          if (existingEmail) {
            setEmailTemplate(existingEmail);
          } else {
            setEmailTemplate({
              CaseID: null,
              CorrespondenceTo: "",
              Body: "",
              CorrespondenceDate: "",
              CorrespondenceSubject: "",
              CorrespondenceFrom: "",
              SentVia: 0,
              FacilityID: facilityID,
              AttachmentList: [],
              RecipientList: [],
              SubjectTypeID: "",
              InsertedBy: "",
            });
          }
          if (recipients.length > 0) {
            setAvailableRecipients(recipients);
          }

          setCallState(CallStates.LOADED);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
    }
  }, [callState]);

  const handleBodyChange = (event, editor) => {
    setEmailTemplate((emailState) => ({
      ...emailState,
      Body: editor.getData(),
    }));
  };

  const save = () => {
    setCallState(CallStates.SAVING);
    saveCorrespondence(api, emailTemplate, facilityID, files, setCallState, setErrorMessage)
      .then(() => {
        setCallState(CallStates.SAVED);
        setTimeout(function () {
          setParentCallState(CallStates.SAVED);
          closeModal(); //should happen anyway with the parent call state but for good measure
        }, 2000);
      })
      .catch((e) => {
        setCallState(CallStates.ERROR);
        setTimeout(function () {
          existingEmail = emailTemplate;
          setFiles([]); //the main problem with this is that if the file upload succeeds and the email send fails, if the user tries to attach the same file it'll then fail on same file.
          setCallState(CallStates.LOADED); //just get rid of the error screen so the user doesn't lose their data
          setErrorMessage(null); // cleanup
        }, 2000);
      });
  };

  const send = () => {
    setCallState(CallStates.SENDING);
    emailTemplate.RecipientList = []; //reset the recipient for if it errors and the user continues. This is almost a placeholder for if mulitple recipients is ever wanted.
    emailTemplate.RecipientList.push({
      ElectronicAddressString: emailTemplate.CorrespondenceTo,
    });
    sendCorrespondence(api, emailTemplate, facilityID, files, setCallState, setErrorMessage)
      .then(() => {
        setCallState(CallStates.SENT);
        setTimeout(function () {
          setParentCallState(CallStates.SENT);
          closeModal(); //should happen anyway with the parent call state but for good measure
        }, 2000);
      })
      .catch((e) => {
        setCallState(CallStates.ERROR);
        setTimeout(function () {
          existingEmail = emailTemplate;
          //specifically not removing the attachment just in case sending a second time somehow still sends it. probbaly need a button to remove form attachment list.
          setFiles([]); //the main problem with this is that if the file upload succeeds and the email send fails, if the user tries to attach the same file it'll then fail on same file.
          setCallState(CallStates.LOADED); //just get rid of the error screen so the user doesn't lose their data
          setErrorMessage(null); // cleanup
        }, 2000);
      });
  };

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSave) {
      save();
    } else {
      send();
    }
  };

  return (
    <PageState callState={callState} errorMessage={errorMessage}>
      <form
        className='email-template'
        onSubmit={(event) => handleSubmit(event)}
      >
        <div style={{ marginTop: "1rem" }}>
          <FormLabel displayValue='To: ' />
          {availableRecipients.length > 0 && (
            <div className='container-75' style={{ marginTop: "0.5rem" }}>
              <SelectInput
                name='CorrespondenceTo'
                value={emailTemplate.CorrespondenceTo === "" ? availableRecipients[0] : emailTemplate.CorrespondenceTo}
                options={availableRecipients}
                labelProperty='ElectronicAddressString'
                valueProperty='ElectronicAddressString'
                handleChange={(event) =>
                  handleInputChange(event, emailTemplate, setEmailTemplate)
                }
              />
            </div>
          )}
        </div>
        <div style={{ marginTop: "1rem" }}>
          <>
            {availableRecipients.length < 1 && emailTemplate && (
              <div style={{ marginTop: "0.5rem" }}>
                <TextInputBorderBox
                  name='CorrespondenceTo'
                  value={emailTemplate.CorrespondenceTo ?? ""}
                  handleChange={(event) =>
                    handleInputChange(event, emailTemplate, setEmailTemplate)
                  }
                />
              </div>
            )}
          </>
        </div>
        {emailTemplate && (
          <>
            {isSave && (
              <div style={{ marginTop: "1rem" }}>
                <FormLabel displayValue='From: ' />
                <div style={{ marginTop: "0.5rem" }}>
                  <TextInputBorderBox
                    name='CorrespondenceFrom'
                    value={emailTemplate.CorrespondenceFrom ?? ""}
                    handleChange={(event) =>
                      handleInputChange(event, emailTemplate, setEmailTemplate)
                    }
                  />
                </div>
              </div>
            )}
            {isSave && (
              <div style={{ marginTop: "1rem" }}>
                <FormLabel displayValue='Date sent: ' />
                <DateInput
                  value={emailTemplate.CorrespondenceDate ?? ""}
                  name='CorrespondenceDate'
                  handleChange={(event) =>
                    handleInputChange(event, emailTemplate, setEmailTemplate)
                  }
                  required={true}
                />
              </div>
            )}
            <div style={{ marginTop: "1rem" }}>
              <FormLabel displayValue='Subject Type: ' />
              {subjectTypes && (
                <div style={{ marginTop: "0.5rem" }}>
                  <SelectInput
                    name='SubjectTypeID'
                    value={emailTemplate.SubjectTypeID ?? subjectTypes[0]}
                    options={subjectTypes}
                    labelProperty='Description'
                    valueProperty='SubjectTypeID'
                    handleChange={(event) =>
                      handleInputChange(event, emailTemplate, setEmailTemplate)
                    }
                  />
                </div>
              )}
            </div>

            <div style={{ marginTop: "1rem" }}>
              <FormLabel displayValue='Subject: ' />
              <div style={{ marginTop: "0.5rem" }}>
                <TextInputBorderBox
                  name='CorrespondenceSubject'
                  value={emailTemplate.CorrespondenceSubject ?? ""}
                  handleChange={(event) =>
                    handleInputChange(event, emailTemplate, setEmailTemplate)
                  }
                />
              </div>
            </div>

            <div style={{ marginTop: "1rem" }}>
              <FormLabel displayValue='Body: ' />
              <div style={{ marginTop: "0.5rem" }}>
                <CKEditor
                  editor={ClassicEditor}
                  data={emailTemplate.Body ?? ""}
                  onChange={handleBodyChange}
                />
              </div>
            </div>

            {emailTemplate.AttachmentList &&
              emailTemplate.AttachmentList.map((item, index) => (
                <ul key={item.GUID}>
                    <li style={{paddingTop: "0.7rem" }}>
                        <a
                        href={`${process.env.REACT_APP_IPRS_WEB_API_FILE_DOWNLOAD_URL}?id=${item.GUID}&Token=${api.getToken()}`}
                        target="_blank" 
                        rel="noopener noreferrer"
                        key={index}
                        style={{ cursor: "pointer"}}
                        >
                            {item.Name}
                        </a>
                    </li>
                </ul>
                
              ))}
            <FileUpload handleChange={(e) => handleFileChange(e)} />
            <div style={{ marginTop: "1rem" }}>
              {isSave && (
                <Button label='Save' handleClick={() => {}} type='submit' />
              )}
              {!isSave && (
                <Button label='Send' handleClick={() => {}} type='submit' />
              )}
            </div>
          </>
        )}
      </form>
    </PageState>
  );
};

export default EmailTemplate;
