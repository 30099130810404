import "../Styles/SharedGenericStyles.css"
import { formatDate } from "../Utils/DateUtils";

export const DateInputWithLabel = ({
  inputLabel,
  value,
  name,
  handleChange,
  required = false,
  disabled = false,
}) => {
  return (
    <div className='clinic-profile-InLine container-100-justified-content'>
      <label>{inputLabel}: </label>
      <div className='container-50-align-left'>
        <input
          type='date'
          name={name}
          value={formatDate(value)}
          onChange={(e) => handleChange(e)}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export const DateInput = ({ value, name, handleChange, disabled = false, required = false }) => {
  return (
    <div>
      <input
        type='date'
        name={name}
        value={formatDate(value)}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
        required={required}
      />
    </div>
  );
};

export const DateTimeInput = ({
  value,
  name,
  handleChange,
  disabled = false,
  required = false
}) => {
  return (
    <div>
      <input
        type='datetime-local'
        name={name}
        value={value}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
        required={required}
      />
    </div>
  );
};

export const TimeInput = ({ value, name, handleChange, disabled = false, required = false }) => {
  return (
    <div>
      <input
        type='time'
        name={name}
        value={value}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
        required={required}
      />
    </div>
  );
};
