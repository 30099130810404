import "../Styles/SharedGenericStyles.css"
import { useState, useEffect } from "react";

export const TextInputWithLabel = ({
  inputLabel,
  value,
  name,
  handleChange,
  required = false,
  disabled = false,
}) => {
  return (
    <div className='clinic-profile-InLine container-100-justified-content'>
      <div>
        <label>{inputLabel}: </label>
      </div>
      <div className='container-50-align-left'>
        <input
          type='text'
          value={value}
          name={name}
          onChange={(e) => handleChange(e)}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export const TextInputBorderBox = ({
  value,
  name,
  handleChange,
  disabled = false,
  required = false
}) => {
  return (
    <input
      className='container-100-border-box'
      type='text'
      value={value}
      name={name}
      onChange={(e) => handleChange(e)}
      disabled={disabled}
      required={required}
    />
  );
};

export const TextInput = ({ value, name, handleChange, disabled = false, required = false }) => {
  return (
    <input
      type='text'
      value={value}
      name={name}
      onChange={(e) => handleChange(e)}
      disabled={disabled}
      required={required}
    />
  );
};
